// eslint-disable-next-line import/named
import { groupBy } from 'lodash';
import { URLKeys, noddiAsync } from 'noddi-async';
import { StoreUnitOccupancyStatus } from 'noddi-async/src/types';
import { ApiErrorMessage, InfoPage, LoadingScreen } from 'noddi-ui';

type StorageUnitListProps = {
  showUnoccupied: boolean;
  storageFacilityId: number;
};

export default function StorageUnitList({ showUnoccupied, storageFacilityId }: StorageUnitListProps) {
  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getStorageUnits,
    input: {
      occupancyStatus: showUnoccupied ? [StoreUnitOccupancyStatus.UNOCCUPIED] : [StoreUnitOccupancyStatus.OCCUPIED],
      storageFacilityId
    }
  });
  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }
  const storageUnitsGroupByRoom = groupBy(data, 'storageArea.name');

  if (!Object.keys(storageUnitsGroupByRoom).length) {
    const title = showUnoccupied ? 'No free storage units found' : 'No occupied storage units found';
    return <InfoPage title={title} message='Try choosing another date or storage facility' />;
  }
  return (
    <div className='mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2'>
      {Object.keys(storageUnitsGroupByRoom).map((roomKey) => {
        const unitsInRooom = storageUnitsGroupByRoom[roomKey]!.sort((a, b) =>
          a.storageArea.name.localeCompare(b.storageArea.name)
        );

        return (
          <div className='flex flex-col gap-2' key={roomKey}>
            <h2 className='font-bold'>{roomKey}</h2>
            <div className='grid grid-cols-4 gap-2'>
              {unitsInRooom.map((unit) => (
                <div className='rounded-xl bg-primary-purple p-4 text-primary-white' key={unit.id}>
                  <p>{unit.name}</p>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
