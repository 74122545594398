export const noddiApps = {
  adminExternal: 'adminExternal',
  adminInternal: 'adminInternal',
  customer: 'customer',
  worker: 'worker',
  org: 'org',
  facility: 'facility',
  work: 'work'
} as const;

export type NoddiApp = keyof typeof noddiApps;
