import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  EmailLogin,
  NoddiFeedbackBox,
  NoddiLogo,
  SectionSpacer,
  useGetScreenDimensions,
  useNavigateOnLoginSuccess
} from 'noddi-ui';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { screenSize } = useGetScreenDimensions();

  const containerHeight = screenSize.height * 0.75;
  const { navigateOnLoginSuccess } = useNavigateOnLoginSuccess();

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card elevation={16}>
          <Header>
            <div style={{ margin: 'auto 0' }}>
              <Typography variant='h3'>Login</Typography>
            </div>
            <NoddiLogo />
          </Header>

          <CardContent style={{ paddingTop: '0px' }}>
            <SectionSpacer>
              <div style={{ width: '100%' }}>
                <EmailLogin
                  setErrorMessage={setErrorMessage}
                  onLoginSuccess={() => navigateOnLoginSuccess({ loginSuccessPath: '/' })}
                />
              </div>
            </SectionSpacer>

            {errorMessage && <NoddiFeedbackBox variant='error' description={errorMessage} />}
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
