import { Field, FieldProps } from 'formik';

import { NoddiSelect } from '../../Inputs';
import { NoddiSelectProps } from '../../Inputs/Select/NoddiSelect';

interface NoddiFormSelectProps<Value> extends NoddiSelectProps<Value> {
  name: string;
}
const NoddiFormSelect = <Value,>(props: NoddiFormSelectProps<Value>) => {
  const { name, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<'' | Value | undefined>) => (
        <NoddiSelect
          {...field}
          {...rest}
          name={name}
          value={field.value ?? ''}
          error={Boolean(form.touched[name] && form.errors[name])}
          onChange={(event) => {
            form.setFieldValue(name, event.target.value);
          }}
        />
      )}
    </Field>
  );
};

export { NoddiFormSelect };
