import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, VerticalLayout } from 'noddi-ui';
import { Navigate } from 'react-router-dom';

import { getNavItems } from '../../NavItems';
import routes from '../appRoutes';

const HomePrivateLayout = () => {
  const navItems = getNavItems();

  const { isLoggedIn, tokenLoginError, isTokenLoadingOnMount } = useAuthContext();

  if (isTokenLoadingOnMount) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getBasePath()} />;
  }

  if (tokenLoginError && !isLoggedIn) {
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getBasePath()} />;
    }
  }
  return <VerticalLayout translations={{ profile: 'My account', logout: 'Log out' }} navItems={navItems} />;
};

export default HomePrivateLayout;
