import { useAuthContext } from 'noddi-provider';
import { useMemo } from 'react';

import { NavItem } from '../../../types/navItem';

export function useFilteredNavItems(navRoutes: NavItem[]) {
  const { userData } = useAuthContext();

  const navItems = useMemo(() => {
    const filteredNavItems = navRoutes.filter((navItem) => {
      const items = navItem.items.filter((item) => {
        if (item.shouldShow) {
          return item.shouldShow();
        }
        return true;
      });
      return items.length > 0;
    });
    return filteredNavItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRoutes, userData]);
  return navItems;
}
