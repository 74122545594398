import { Card, CardContent } from '@mui/material';
import { PropsWithChildren } from 'react';

import { NoddiCardProps } from '../../../types';
import { FieldWrapper } from '../Form';
import { NoddiHeader } from '../NoddiHeader';

const NoddiCardContent = ({
  children,
  disableSpacing,
  cardContentSx
}: PropsWithChildren<Pick<NoddiCardProps, 'disableSpacing' | 'cardContentSx'>>) => {
  return (
    <CardContent sx={cardContentSx}>{disableSpacing ? children : <FieldWrapper>{children}</FieldWrapper>}</CardContent>
  );
};

export const NoddiCard = ({
  title,
  description,
  headerNode,
  children,
  disableContentPadding,
  disableSpacing,
  cardSx,
  cardContentSx
}: PropsWithChildren<NoddiCardProps>) => {
  return (
    <Card sx={cardSx}>
      {(title || description) && (
        <NoddiHeader
          title={title}
          description={description}
          node={headerNode}
          sx={{ padding: '24px' }}
          titleVariant='h5'
        />
      )}

      {disableContentPadding ? (
        children
      ) : (
        <NoddiCardContent disableSpacing={disableSpacing} cardContentSx={cardContentSx}>
          {children}
        </NoddiCardContent>
      )}
    </Card>
  );
};
