import { SxProps } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { colors, useIsMobile } from 'noddi-ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export interface LinkProps {
  title: string;
  path?: string;
}

export interface BreadcrumbProps {
  links: LinkProps[];
  maxItems?: number;
  separator?: string;
  sx?: SxProps;
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin: 0 4px;
  }

  @media (min-width: 600px) {
    .MuiBreadcrumbs-separator {
      margin: 0 8px;
    }
  }
`;

const styledFont = css`
  font-size: 14px;
  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

const StyledFont = styled(Typography)`
  ${styledFont};
`;

const StyledBreadcrumbLink = styled(Link)`
  ${styledFont};
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${colors?.primary?.orange};
    text-decoration: underline;
  }
`;

export function NoddiBreadcrumbs({ links, sx, maxItems = 4, separator = '/' }: BreadcrumbProps) {
  const isMobile = useIsMobile();

  return (
    <StyledBreadcrumbs maxItems={isMobile ? 3 : maxItems} separator={separator} sx={sx}>
      {links.map((link) => {
        return link.path ? (
          <StyledBreadcrumbLink color='inherit' to={link.path} key={link.path}>
            {link.title}
          </StyledBreadcrumbLink>
        ) : (
          <StyledFont color={colors.primary.black} fontWeight={500} key={link.title}>
            {link.title}
          </StyledFont>
        );
      })}
    </StyledBreadcrumbs>
  );
}
